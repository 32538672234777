/* You can add global styles to this file, and also import other style files */
@import '~normalize.css';

@font-face {
  font-family: "Museo Sans Cyrl Light";
  src: url(assets/fonts/museo-sans-cyrl-100.ttf);
}

@font-face {
  font-family: "Museo Sans Cyrl Normal";
  src: url(assets/fonts/museo-sans-cyrl-300.ttf);
}

@font-face {
  font-family: "Museo Sans Cyrl Bolder";
  src: url(assets/fonts/museo-sans-cyrl-500.ttf);
}

h1 {
  font-family: Museo Sans Cyrl Bolder, serif;
  font-size: 24px;
  text-align: left;
}

h2 {
  font-family: Museo Sans Cyrl Light, serif;
  margin-top: -5px;
  font-size: 22px;
  color: #6B777E;
}

.error-message {
  font-family: Museo Sans Cyrl Light, serif;
  font-size: 14px;
  color: red;
}
